"use strict";

// ===========================================
//  library
// ===========================================
// import { initializeLenis } from "../utility/_lenis";
import { textKvAnim } from "../utility/_layerModules";
import * as modGsap from "../utility/_gsap";
modGsap.init({ scrollTrigger: true });

// ===========================================
//  variable
// ===========================================
const activeClass = "is-active";

// ===========================================
//  function / 処理
// ===========================================

// ===========================================
//  setup
// ===========================================
// ===========================================
//  init / 初期化
// ===========================================
const init = () => {
  // figureKvAnim();
  modGsap.onceAnimation();
  textKvAnim();
};
// ===========================================
//  load / 読み込み
// ===========================================

window.addEventListener("DOMContentLoaded", () => {
  init();
});
