"use strict";

// ===========================================
//  variable / 変数宣言
// ===========================================
const indexKvTl = modGsap.gsap.timeline();
const pageTitle = document.querySelector(".main-kv-title__text");
const pageTextTitle = document.querySelector(".text-kv-letters");
const pageContent = document.querySelector(".content-inner");
const kvFigureUpper = document.querySelector(".main-kv-figure__upper");
const kvFigureUpperImage = document.querySelector(".main-kv-figure__upper img");
// ===========================================
//  library / ライブラリ
// ===========================================
import * as modGsap from "./_gsap";
modGsap.init({ scrollTrigger: true });
import { slideInLetters, sideInLetters } from "./_scrollAnim";

// ===========================================
//  setup /
// ===========================================
export const figureKvAnim = () => {
  const figureKvTl = modGsap.gsap.timeline({
    defaults: {
      duration: 1.5,
    },
  });

  figureKvTl.fromTo(
    kvFigureUpper,
    {
      clipPath: "polygon(100% 100%, 0% 100%, 0% 100%, 100% 100%)",
    },
    {
      clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)",
      ease: "power2.inOut",
    },
    0
  );
  figureKvTl.from(
    kvFigureUpperImage,
    {
      filter: "blur(8px)",
      scaleY: 1.3,
      scaleX: 1.2,
    },
    0
  );
  figureKvTl.add(slideInLetters(pageTitle));

  figureKvTl.add(() => {
    pageTitle.classList.add("is-active");
  });
};
export const textKvAnim = () => {
  const textKvTl = modGsap.gsap.timeline({
    defaults: {
      duration: 1.5,
    },
  });

  textKvTl.add(slideInLetters(pageTextTitle));

  textKvTl.add(() => {
    pageContent.classList.add("is-active");
  }, "+=0.7");
};
